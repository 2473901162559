import React from "react";
import { useTranslation } from "react-i18next"
import unresonable_logo from "../Images/Logos/unreasonable-seal-black.png"
import EvercommLogo from '../Images/Logos/Evercomm-Logo_white-bg1.png'




const Footer = () => {
  const { t } = useTranslation("menu")

  return (
    <div className="row d-flex justify-content-center m-0 footer" style={{ backgroundColor: '#f2fafb' }}>
      <div className="bg-warning mb-4" style={{ height: 13 }}></div>
      <div className="d-flex col-12 justify-content-center px-xl-5 px-lg-5 px-md-5 px-sm-2 px-3" >
        <div className="row d-flex col-12 mb-4 footer_logo px-xl-5 px-lg-2 px-md-0 px-sm-0 px-0" style={{ textAlign: 'start', justifyContent: "space-between"}}>
         {/* <div className="col-xl-1 col-lg-1 col-md-0 col-sm-0 col-0 me-lg-5"></div> */}
         <div className="footer-text-one p-0" style={{display: "none"}}>
          <img className="py-auto mb-4" src={EvercommLogo} alt="evercomm_logo" style={{ width: 150 }} />

            <p className="">Evercomm is a multiple award-winning company with carbon reduction engineering solutions to help transition the industrial world towards a lower-carbon future. Our industry solutions, developed from deep engineering and IT expertise, are stringently aligned to ISO 14064, 14067 and 14068 standards and confidently employed by global corporations as an integral part of their transition towards net-zero outcomes.</p>
            <p className="footer-item" style={{cursor: "pointer" , color: "#0048b1"}} onClick={() => window.open('https://www.icmagroup.org/sustainable-finance/icma-and-other-sustainable-finance-initiatives/mas-code-of-conduct-for-esg-rating-and-data-product-providers-2/', '_blank')}>Compliant to MAS COC for ESG data providers</p>  
            
          </div>
          <div className="d-flex col-12 p-0" style={{justifyContent: "space-between", flexWrap: "wrap"}}>
          {footer.map((v, k) => {
            return <div className="footer-list" key={k} style={{width: "max-content"}}>
              <p className="blu_title my-3">{t(v.title)}</p>
              {v.subTitle.map((f, k1) => {
                return <p className="my-1 footer-item" style={{ fontSize: 16, cursor: 'pointer' }} key={k1}><a style={{ color: '#000', textDecoration: 'none' }} href={f.link} onClick={e => f.link === '#!' ? e.preventDefault() : null}>{t(f.title)}</a></p>
              })
              }
              
            </div>
          })
          }
          {/* <div className="" style={{ width: "fit-content" }}>
            <a title="Evercomm Singapore – an Unreasonable company" target="_blank" rel="noopener" href="https://unreasonablegroup.com/c/?id=6b2b16ad-2a57-4846-9cc6-5498ce21fd1c"><img className="unreasonable_logo mt-3" alt="Official Unreasonable Company" width="200" height="100" src={unresonable_logo} /></a>
          <p className="text ps-1">Compliant to MAS COC for ESG data providers</p>
          </div> */}
          <div className="footer-text-two " style={{width: 300, display: "block", fontSize: 16}}>
          <img className="py-auto mb-4" src={EvercommLogo} alt="evercomm_logo" style={{ width: 200 }} />

            <p>Evercomm is a multiple award-winning company specialising in ESG and sustainability reporting, as well as carbon reduction engineering solutions, driving the transition to a lower-carbon future.
With deep expertise in engineering and IT, our industry solutions are stringently aligned with global standards, including the GHG Protocol and ISO 14064, 14067, and 14068. Trusted by leading global corporations, our solutions play an integral role in achieving carbon-neutral and net-zero goals.
</p>
            <p className="footer-item" style={{cursor: "pointer" , color: "#0048b1"}} onClick={() => window.open('https://www.icmagroup.org/sustainable-finance/icma-and-other-sustainable-finance-initiatives/mas-code-of-conduct-for-esg-rating-and-data-product-providers-2/', '_blank')}>Compliant to MAS COC for ESG data providers</p>  
          </div>
          </div>
        </div>
      </div>
      
      <div className="d-flex justify-content-center p-0 " style={{ backgroundColor: '#0048b1', color: '#ffff' }}>
        <div className="col-11 d-flex flex-wrap justify-content-center">
          <div className="col-lg-6 col-md-7 col-sm-12 d-flex align-items-center justify-content-center text-lg-start text-md-start text-center my-1 my-lg-3 my-md-3 my-sm-1" style={{}}><p className="m-0 w-100">© 2025 Evercomm Singapore Pte Ltd. All rights reserved.</p></div>
          <div className="col-lg-6 col-md-5 col-sm-12 d-flex align-items-center justify-content-center text-lg-end text-md-end text-center my-1 my-lg-3 my-md-3 my-sm-1" style={{}}><p className="m-0 w-100 "><span className="" style={{cursor: "pointer"}} onClick={() => window.location.assign('/privacy')}>Privacy Policy</span> | <span className="" style={{cursor: "pointer"}} onClick={() => window.location.assign('/terms-of-use')}>Terms of Use</span></p></div>
        </div>
      </div>
     
    </div>
  )
};
export default Footer;

const footer = [
  {
    title: "Home",
    subTitle: [
      // { title: "Our Coustomers", link: "#!" },
      { title: "Reporting & Solutions", link: "/home#enterprise-soln" },
      { title: "Partners", link: "/home#partners" }]
  },
  {
    title: "Reporting & Solutions",
    subTitle: [
      { title: "Overview", link: "/reporting-decarbonisation-solutions" },
      { title: "NXMap", link: "/reporting-decarbonisation-solutions/NXMap" },
      { title: "NXOps", link: "/reporting-decarbonisation-solutions/NXOps" },
      { title: "NXPlan", link: "/reporting-decarbonisation-solutions/NXPlan" },
      // { title: "NXWorld", link: "/reporting-decarbonisation-solutions/NXWorld" }
    ]
  },
  // {
  //   title: "Media & Awards",
  //   subTitle: [
  //     { title: "Outstanding Media & Award Cases", link: "#!" },
  //     { title: "Media", link: "#!" },
  //     { title: "Awards", link: "#!" },
  //     { title: "Happening Now", link: "#!" }]
  // },
  // {
  //   title: "Technology",
  //   subTitle: [
  //     { title: "About", link: "/about" }]
  // },
  {
    title: "About",
    subTitle: [
      { title: "About Evercomm", link: "/about" },
      { title: "Leadership Team", link: "/about#leadership-team" },
      // { title: "Testimonials", link: "#!" },
      // { title: "Partners/Investors", link: "#!" }
    ]
  },
  {
    title: "Contact",
    subTitle: [
      { title: "Where to find us", link: "/contact" }]
  }
]